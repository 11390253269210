import { DateTime } from 'luxon'
import Address from 'shared/models/Address.model'

export enum PartnerRole {
  SUPPLIER = 'supplier',
  CUSTOMER = 'customer',
  FORWARDER = 'forwarder',
  CARRIER = 'carrier',
}

interface CompanySitePartnerRole {
  id: number
  companyId: number
  siteId: number
  partnerId: number
  internalReference?: string
  role: string
}

export interface PartnerCompany {
  id: number
  name: string
  companyUserRolesCount: number
  claimed: boolean
  address?: Address
  transitional?: boolean
}

export interface CreatorCompany {
  id: number
  name: string
}

export interface LatestBooking {
  createdAt: DateTime
  creatorCompany: CreatorCompany
}

export interface CompanySitePartnerRoleExtended extends CompanySitePartnerRole {
  partner: PartnerCompany
  latestBooking?: LatestBooking
}
